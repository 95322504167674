import React from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/Home.module.scss';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import hero_image from './static/home_hero_image.png'
import innovation_icon from './static/innovation_icon.svg'
import progression_icon from './static/progression_icon.svg'
import community_icon from './static/community_icon.svg'
import google_for_startups_logo from './static/google_for_startups_logo.png'

const Home = () => (
  <>
  <Helmet>
      <title>Cassy</title>
    </Helmet>
<NavBar style={{ backgroundColor:'#FFFFFF'}}/>
  <div className={`${s.howItWorks} ${s.student}`}>
    <div className={s.sectionOne}>
      <div className={s.grid}>
        <div>
          <h1>
            <u>Hermes</u>
            <br/>Available everywhere
            <br/>Download <u>for free</u>
          </h1>
          <h2 className={`${s.subtitle} my-4`}>Why browse the web without it?</h2>
          <Button href='/hermes' className={`btnFill lg ${s.heroCTA}`}>Learn More</Button>
        </div>
        <img src={hero_image}/>
      </div>
    </div>
    <div className={s.sectionTwo}>
    <h2 className={`text-center mt-5 ${s.welcomeMessage}`}>Welcome to Cassy, a technology company</h2>
    <p className={`text-center fs-16`}>We're on a mission to innovate and invent new technology that furthers the material and social progression of mankind</p>
    <div className={s.grid}>
        <div className='mb-0'>
            <img src={innovation_icon}/>
            <h4 className='text-center fs-20 mt-4'><strong>Engineering Forward</strong></h4>
            <p className='text-center fs-18'>
            It's our passion to create, solve complex problems, and engineer
            new solutions. We start by breaking down every known assumption and
             rebuilding with what's left.
            </p>
        </div>
        <div className='mb-0'>
            <img src={progression_icon}/>
            <h4 className='text-center fs-20 mt-4'><strong>Relentlessly Progressing</strong></h4>
            <p className='text-center fs-18'>
            It is important to us that we create useful technology that provide
            solutions and enable you to move past obstacles. What we develop
            and offer to the world must be a tool for progression.
            </p>
        </div>
        <div className='mb-0'>
            <img src={community_icon}/>
            <h4 className='text-center fs-20 mt-4'><strong>Charishing Community</strong></h4>
            <p className='text-center fs-18'>
            We build for our community and the world. With each release, we
            heed your feedback to then quickly generate and release an updated
            version that better serves our you.
            </p>
        </div>
    </div>
    <div className={s.sponsorSection}>
      <p>Sponsored by</p>
      <div className={s.sponsors}>
        <img src={google_for_startups_logo}/>
      </div>
    </div>
    </div>
    </div>
    <Footer />
</>
);

export default (Home);
