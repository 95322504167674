import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import textLogo from '../../Static/Logo/whitelogo.svg';
import darkTextLogo from '../../Static/Logo/darklogo.svg';
import s from './NavBar.module.scss'

export const NavBar = ({style, className, dark}) => {
  return <Navbar expand="lg" style={{...style, padding:'5px 10px'}} className={`${s.nav} ${className}`}>
        <Navbar.Brand href="/">
            <img
                alt=""
                src={dark ? darkTextLogo : textLogo}
                height="45"
                className="d-inline-block align-center"
            />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav
            activeKey="/home"
            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            className="me-auto">
            <NavDropdown.Divider className={`${s.mobile} ${s.divider}`} />
            <Nav.Item className={s.mobile}>
                <Button className={`btnLink dblue`} href='/'>Home</Button>
            </Nav.Item>
            <Nav.Item className={s.mobile}>
                <Button className={`btnLink dblue`} href='/hermes'>Hermes</Button>
            </Nav.Item>
            <Nav.Item className={s.mobile}>
                <Button className={'btnLink dblue '} href='/contact'>Contact</Button>
            </Nav.Item>
            <Nav.Item className={s.mobile}>
                <Button className={'btnLink dblue '} href='/hermes/chromeinstall'>For Chrome</Button>
            </Nav.Item>
            <Nav.Item className={s.mobile}>
                <Button className={'btnLink dblue '} href='/hermes/iosinstall'>For iOS</Button>
            </Nav.Item>
        </Nav>
        <Nav className={s.web}>
            <Button className={`btnLink dblue`} href='/'>Home</Button>
            <Button className={`btnLink dblue`} href='/hermes'>Hermes</Button>
            <Button className={`btnLink dblue`} href='/contact'>Contact</Button>
            <Button className={`btnLink dblue`} href='/hermes/chromeinstall'>For Chrome</Button>
            <Button className={`btnLink dblue`} href='/hermes/iosinstall'>For iOS</Button>
        </Nav>
    </Navbar.Collapse>
</Navbar>
}
