import React, { useState, useEffect } from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import { useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import { TextInput } from '../../Components/Input/Input';
import Footer from './Footer';
import Form from 'react-bootstrap/Form'
import { validateEmail, push } from '../../Utils/validation';
import { Root } from '../../Api/Root';
import s from './styles/Contact.module.scss';
import {Tile} from '../../Components/Tile/Tile'
import { Helmet } from 'react-helmet';

const HermesInterest = () => {
    const useQuery = () => { return new URLSearchParams(useLocation().search) }
    const testTiktokClickId = useQuery().get('tt_test_id')
    const tiktokClickId = useQuery().get('ttclid')
    const referralId = useQuery().get('rid')

    const [request, setRequest] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)

    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
      if (!!referralId) {
        Root.miscApi.logReferralLinkClick(referralId, 'hermes_interest', Intl.DateTimeFormat().resolvedOptions().timeZone).catch(error => {});
      }
    }, []);

    function handleChange (event) {
      const { name, value } = event.target
        setRequest(prevState => ({ ...prevState, [name]: value }))
    }

      function validate (request) {
        var errors = {}
         if(!request.email || !validateEmail(request.email)) {
            push(errors, 'email', 'Valid email is required ')
          }
        return errors
      }

      function handleSubmit () {
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        const errors = validate(request)
            if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
          if(Object.keys(request).length > 0){
            Root.miscApi.submitHermesInterest(request.email, request.firstName, 'hermes_interest', referralId).then(() => {
                setFormResponse("Success!");
                setSubmitted(true);
                setRequest({});
                setFormErrors({});
            }).catch(() =>
            setFormResponseError("Something went wrong, please try again later")
          )
        }
      }

      function handleLearnMore() {
        if (!!referralId) {
          window.open(`https://cassy.dev/hermes?rid=${referralId}`, '_blank');
        } else {
          window.open(`https://cassy.dev/hermes`, '_blank');
        }

        fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            if (!!tiktokClickId) {
              Root.miscApi.logLearnMoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, tiktokClickId).catch(error => {});
            } else if (!!testTiktokClickId) {
              Root.miscApi.logLearnMoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, testTiktokClickId).catch(error => {});
            } else {
              Root.miscApi.logLearnMoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone).catch(error => {});
            }
          })
          .catch(error => {
              console.log('Error:', error);
          });
      }


  return (
    <>
    <Helmet>
        <title>Interest - Hermes</title>
      </Helmet>
       <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.login}>
        <div className={s.form}>
          <h1>Get Started With Hermes</h1>
          {submitted ?
            <>
            <p className='text-muted mb-4'>Great! You should receive an email
            with more information soon, but feel free to click below to learn
            more about Hermes.</p>
            <Button className={`btnFill wide mt-3 ${s.submitButton}`} onClick={handleLearnMore}>Learn more about Hermes</Button>
            </> :

          <><p className='text-muted mb-4'>Provide your name and email, and we
          will send you more information on how to add Hermes to your browser.</p>
            <Form>
                <TextInput containerClassName='mb-3' errors={formErrors} name={'firstName'} value={request.firstName || ''} onChange={handleChange} type={'text'} placeholder={"First Name"}/>
                <TextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email || ''} onChange={handleChange} type={'text'} placeholder={"Email"}/>
                <Button className={`btnFill wide mt-3 ${s.submitButton}`} onClick={handleSubmit}>Submit</Button>
            </Form>
            <p className='form-error text-center mt-2'>{formResponseError}</p></>
          }
            {formResponse && <p style={{textAlign:"center", marginTop:"1em"}}><Tile color='purple'>{formResponse}</Tile></p>}
        </div>
      </div>
        <Footer />
    </>
  );
};

export default (HermesInterest);
