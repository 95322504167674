
import axios from 'axios';
import * as vars from './vars';

export function submitHermesInterest (email, name, page, referralId = null){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/metrics/interest',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        name: name,
        page: page,
        referralId: referralId ?? 'none',
        }
    })
}

export function logLearnMoreClick (ipAddress, timezone, referralId = null) {
  let deviceId = (Math.random() + 1).toString(36).substring(7);
  const data = {
    action: 'click',
    deviceId: deviceId,
    ipAddress: ipAddress,
    timezone: timezone,
  };

  if (!!referralId) {
    data['referralId'] = referralId;
  }

  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v1/metrics/learn_hermes',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
  })
}

export function contactSupport (email, name, subject, message){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/support/form_contact',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        name: name,
        subject: subject,
        message: message,
        }
    })
}

export function subscribeToNewsletter (email) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/newsletter/subscription',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        }
    })
}

export function acknowledgeDownload () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/registration/onboard',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function acknowledgeUpdate () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/registration/update',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function logChromeStoreClick (ipAddress, timezone, tokenName, tokenId = null) {
  let deviceId = (Math.random() + 1).toString(36).substring(7);
  const data = {
    action: 'click',
    deviceId: deviceId,
    ipAddress: ipAddress,
    timezone: timezone,
  };

  if (!!tokenId) {
    data[tokenName] = tokenId;
  }

  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v1/metrics/chrome_store',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
  })
}

export function logReferralLinkClick (referralId, page, timezone) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v1/metrics/referralLinkClick',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          referralId: referralId,
          timezone: timezone,
          page: page,
        }
    })
}
