import { React, useEffect, useState } from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import {TestimonySwitcher} from '../../Components/Switchers/TestimonySwitcher'
import s from './styles/Hermes.module.scss';
import { useLocation } from 'react-router-dom'
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import {FaStar, FaArrowRight} from 'react-icons/fa'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
import { Helmet } from 'react-helmet';
import { Root } from '../../Api/Root';

import book_icon from './static/book_icon.svg'
import heavy_research from './static/heavy_research.png'
import hero_image from './static/hero_image.png'
import lightning_icon from './static/lightning_icon.svg'
import select_window_icon from './static/select_window_icon.svg'
import shield_icon from './static/shield_icon.svg'
import tune_icon from './static/tune_icon.svg'
import simple_browsing from './static/simple_browsing.png'
import its_performance from './static/its_performance.png'
import its_performance_mobile from './static/its_performance_mobile.png'
import your_performance from './static/your_performance.png'
import your_performance_mobile from './static/your_performance_mobile.png'
import quotation_mark from './static/quotation_mark.svg'
import splash from './static/splash.svg'

import evidence_video from './static/evidence.mp4'

const Hermes = () => {
  const useQuery = () => { return new URLSearchParams(useLocation().search) }
  const testTiktokClickId = useQuery().get('tt_test_id')
  const tiktokClickId = useQuery().get('ttclid')
  const referralId = useQuery().get('rid')
  const [currentWord, setCurrentWord] = useState('researching');
  var counter = 0;
  const words = ['researching', 'reading', 'studying'];

  useEffect(() => {
    if (!!referralId) {
      Root.miscApi.logReferralLinkClick(referralId, 'hermes_landing', Intl.DateTimeFormat().resolvedOptions().timeZone).catch(error => {});
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        counter++;
        setCurrentWord(words[counter % 3]);
      } catch(e) {
        setCurrentWord(words[0]);
      }
    }, 1000); // Update every 1000ms (1 second)

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, []);

  function handleClick() {
    window.open('https://cassy.dev/hermes/chromeinstall', '_blank');

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        if (!!tiktokClickId) {
          Root.miscApi.logChromeStoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, 'tiktokClickId', tiktokClickId).catch(error => {});
        } else if (!!testTiktokClickId) {
          Root.miscApi.logChromeStoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, 'testTiktokClickId', testTiktokClickId).catch(error => {});
        } else if (!!referralId) {
          Root.miscApi.logChromeStoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, 'referralId', referralId).catch(error => {});
        } else {
          Root.miscApi.logChromeStoreClick(data.ip, Intl.DateTimeFormat().resolvedOptions().timeZone, 'none').catch(error => {});
        }
      })
      .catch(error => {
          console.log('Error:', error);
      });
  }

  return <>
  <Helmet>
      <title>Hermes - Cassy</title>
    </Helmet>
<NavBar style={{ backgroundColor:'#FFFFFF'}}/>
  <div className={`${s.howItWorks} ${s.student}`}>
    <div className={s.sectionOne} style={{ backgroundColor:'#F6E7D9'}}>
      <div className={s.grid}>
        <div>
          <h1 className={s.hermesHeader}>
            Save time {currentWord}<br/> while <span className={s.keyword}>increasing</span> your understanding.
          </h1>
          <h2 className={`${s.subtitle} mt-2 mb-4`}>Hermes is a free browser extension saving students and professionals valuable time on while they work.</h2>
          <Button onClick={()=> handleClick()} className={`btnFill lg ${s.ctaFillButton}`}>Add Hermes on Chrome</Button>
        </div>
        <div className={`${s.heroVideoWeb}`}>
          <video  loop autoPlay muted>
            <source src= { evidence_video } type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
          </video>
        </div>
        <div className={`${s.heroVideoMobile}`}>
          <img src="https://cdn.cassy.dev/hero_image.png"/>
        </div>
      </div>
    </div>
    <div className={s.sectionTwo}>
        <div className={s.container}>
            <h2 className='text-center'>Why Hermes</h2>
            <div className={s.grid}>
                <div className='mb-0'>
                    <img src={tune_icon}/>
                    <h4 className='text-center fs-20 mb-4'><strong>Every Website Tailored To You</strong></h4>
                    <p className='text-center fs-18'>
                    Hermes provides you with an understanding of the website
                    that is catered to you. As you ask questions, Hermes will
                    begin to convey the website in a way you’ll easily
                    comprehend.
                    </p>
                </div>
                <div className='mb-0'>
                    <img src={shield_icon}/>
                    <h4 className='text-center fs-20 mb-4'><strong>Instant Answers With Citations</strong></h4>
                    <p className='text-center fs-18'>
                    Ask questions about a website's content for instant answers
                    with citations. Hermes finds evidence within the document
                    to support its claims so you can have confidence.</p>
                </div>
                <div className='mb-0'>
                    <img src={select_window_icon}/>
                    <h4 className='text-center fs-20 mb-4'><strong>Research Multiple Websites</strong></h4>
                    <p className='text-center fs-18'>
                    Compare multiple websites' topics and arguments seamlessly.
                    As you move across various documents, websites, and tabs,
                    Hermes follows you and understands it all.
                    </p>
                </div>
            </div>
            <div className={s.andMore}>and much more...</div>
        </div>
    </div>

    <div className={`${s.toggleGrid}`}>
      <div className={s.grid}>
        <span className={s.step}>
          <span>
            <h4>Use Hermes for Extensive Research</h4>
            <p className={s.gridContent}>The average article in a research journal has around 8 pages,
            or 3500 words, with many papers containing even 10-20 pages. Combing
             through pages and pages of research can be time-consuming, and
             after a while, the information overload can become overwhelming.</p>
            <p className={s.gridContent}><span className={s.keyword}>Avoid cognitive fatigue</span>, and expedite your research with Hermes,
             without sacrificing your understanding.</p>
             <Button className={`btnOutline lg d-block mx-auto mb-5 ${s.ctaButton}`} onClick={()=> handleClick()}>Supercharge your research</Button>
          </span>
        </span>
        <span><img src={heavy_research}/></span>
      </div>
      <div className={s.grid}>
          <span className={`${s.stagger}`}>
            <span>
              <h4><strong>Use Hermes for Simple Web Browsing</strong></h4>
              <p className={s.gridContent}>Only 51% of people who read an online news story read the
              entire article. The remaining 49% read part of the article or
              just the headline. Of the people who do finish the article, 60%
              skim rather than read the entire thing. We get it; you don't have
              the time or the attention span to read the entire article in
              depth. Most people only devote 15 seconds to the article, because
              their time is valuable. </p>
              <p className={s.gridContent}><span className={s.keyword}>Use Hermes to better comprehend the entire article in the same
              amount of time.</span></p>
              <Button className={`btnOutline lg d-block mx-auto mb-5 ${s.ctaButton}`} onClick={()=> handleClick()}>Browse the web with Hermes</Button>
            </span>
          </span>
          <span><img src={simple_browsing}/></span>
      </div>
    </div>

    <TestimonySwitcher containerClassName={s.sectionThree} />


    <div className={`${s.sectionFour} `}>
      <h2 className='text-center my-2'>How does Hermes measure up?</h2>
      <div className={`${s.testimonialContainer}`}>
        <p className={`text-center fs-18 mb-4 ${s.testimonialSubtitle}`}>We know Hermes excels, but we'll let the results speak for themselves.</p>
      </div>
      <div className={`mb-5 ${s.testimonialContainer}`}>
        <div className={`${s.container} ${s.leftContainer}`}>
          <h4>Your Time Saved</h4>
          <p className={`text-center fs-16`}>We made researching easy; no prep is required.<sup></sup></p>
          <div className={`${s.containerImg}`}>
          <img className={s.imageWeb} src={its_performance}/>
          <img className={s.imageMobile} src={its_performance_mobile}/>
          </div>
        </div>
        <div className={`${s.containerMedian}`}>
          <h2>+</h2>
        </div>
        <div className={`${s.container} ${s.rightContainer}`}>
          <h4>Your Comprehension Increased</h4>
          <p className={`text-center fs-16`}>With Hermes, you'll understand more in a fraction of the time.<sup>2</sup></p>
          <div className={`${s.containerImg}`}>
            <img className={s.imageWeb} src={your_performance}/>
            <img className={s.imageMobile} src={your_performance_mobile}/>
          </div>
        </div>
      </div>
    </div>

    <div className={``}>
      <Button className={`btnOutline lg d-block mx-auto mb-5 ${s.bottomCTA}`} onClick={()=> handleClick()}>Get started with Hermes</Button>
    </div>
    <div className={`${s.citations} `}>
    Footer: <sup>1</sup> With Hermes, you're able to start asking AI questions
    related to the contents on the page as soon as the page loads in front of
    you. You do not need to save the page, format it, and then upload to a
    third-party service.<sup>2</sup> Studies indicate that users who read
    websites and articles with Hermes tend to complete the reading in a shorter
    amount of time and have higher levels of comprehension of the contents
    than those who read without Hermes. <sup>3</sup> The amount of time needed
    to read enough of the web contents to be able to talk about and maintain a
    conversation about the contents, accuratly. <sup>4</sup> When answering a
    reading comprehension quiz related to the contents of the web document,
    users who used Hermes scored higher, on average, than those who did not use
    Hermes.
    </div>
  </div>
  <Footer />
</>
}

export default (Hermes);
