import React, {useEffect} from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { Root } from '../../Api/Root';

const HermesLatest = () => {

  useEffect(() => {
    Root.miscApi.acknowledgeUpdate().catch(error => {})
  }, []);

  return <>
  <Helmet>
      <title>Hermes 1.7.2 - Cassy</title>
    </Helmet>
    <div className={s.blog}>
    <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.sectionOne}>
        <div className={`${s.container}`}>
          <h1><strong>Hermes 1.7.2</strong></h1>
          <p className={`text-center fs-18`}>Our latest and most capable research assistant is here.</p>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
      <h2>What's New</h2>
      <h4 style={{ marginTop:'30px'}}>Minor Bug Fixes</h4>
      <p>When the extension is initially loaded, an error message related to
      creating a topic was frequently shown. This has been fixed, and the
      extension should load more smoothly now.</p>
      <h2>What's Available</h2>
      <h4 style={{ marginTop:'30px'}}>Suggested Prompts</h4>
      <p>We understand it can be hard to come up with prompts and questions to
      ask Hermes, so we'll help you with that. In the prompt box, Hermes offers
      you a suggested prompt that you can use by pressing the Tab key. These
      suggestions are tailored to the document that you are reading, previous
      documents, and your previous prompts. If you'd rather provide your own
      prompt, simply begin typing, and the suggested prompt will go away.</p>

      <h4 style={{ marginTop:'30px'}}>Local Files</h4>
      <p>Whether you're reading a PDF on a website or one that's been
      downloaded on to your computer, Hermes can help you understand it. Simply
      open the document in your browser, and ask away.</p>

      <h4 style={{ marginTop:'30'}}>More File Types Can Be Interpreted</h4>
      <p>Every website is unique and handles files completely different, but
        we know you need Hermes to help you interpret them all. In this latest
        update, Hermes is equipped to read in provide context on more file
        types than ever.</p>

      <h4 style={{ marginTop:'30px'}}>Faster Response Times</h4>
      <p>We understand your time is
        limited, and the main reason you're using Hermes is to save time, so
        we're constantly looking for ways to improve the quality of the
        responses while also decreasing the time it takes to generate the
        responses. In this update, we're decreased that time by a noticable 33%.</p>
      </div>
    </div>
    <Footer />
  </>
};

export default (HermesLatest);
